.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.toast-position {
  top: 2rem !important;
}

.table-container{
  max-height: 300px;
  overflow-y: auto
  }

.scrollable{
  height: 100%;
  position: relative;
  
}
.scrollable>div{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow-y: auto
}

.collapse-button {
  display: block;
  width: 100%;
}

.collapse-content.collapsed {
  display: none;
}

.collapsed-content.expanded {
  display: block;
}

.pozz-top {
  margin-top: -2%;
}

.front{
  z-index: 1;
}
.back{
  z-index: -1;
}

.center{
  display: grid;
  place-items: center;
}

.hoverColor:hover{
  color:#0085c9;
}

.hoverBGColor:hover{
  background-color:#FFFCFF;
}
.breadcrumbBullet::marker {
  color: red;
}

/* Marquee styles */
.marquee {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);

}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
}

.marquee__content img {
  max-width: 2rem;
  width: 100%;
  object-fit: contain;
}

.marquee__content > * {
  flex: 0 0 auto;

 
  
  
  text-align: center;
}

.marquee__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shrinkAnim { transition: transform 0.5s; }
.shrinkAnim:hover { transform: scale(0.95); }
.growAnim { transition: transform 0.5s; }
.growAnim:hover { transform: scale(1.05); }


.link {
  color: #000;
}
.link:hover {
   color: #0085c9
}

@keyframes scroll {
  from { transform: translateX(-0.8%); }
  to { transform: translateX(calc(-33.5% - var(--gap))); }
}
/* Enable animation */
.enable-animation .marquee__content {
  animation: scroll 10s  linear infinite;
}


circle.fg {
  transform: rotate(-90deg);
  transform-origin: 125px 125px;
}






@media screen and (min-width: 860px) {
  .mob {
    display: none;

  }



}
@media screen and (max-width: 860px) {
  .mob {
    display: block;

  }

}

@media screen and (min-width: 860px) {
  .desk {
    display: block;

  }



}
@media screen and (max-width: 860px) {
  .desk {
    display: none;

  }

}


@media screen and (max-width: 1020px) {
  .panel{
        float: center;
        display: block;
  }
 
   .checkbox{
        margin-top: 50%;
  }

  
  

  
}
@media screen and (min-width: 1020px) {
 
 

  @keyframes typing {
    from {
      width: 0;
    }
  }
  
  @keyframes effect {
    50% {
      border-color: transparent;
    }
  }


  .typing {
    height: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    
  }
  .typing-effect {
    
    border-right: 3px solid;
    
    
  }
  .typing-effect-12{
    width: 12ch;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    animation: typing 1s steps(12), effect .5s   alternate;
  }
  .typing-effect-13{
    width: 14ch;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    animation: 5s typing 1s steps(14), effect .5s step-end infinite alternate;
  }
  .typing-effect-14{
    width: 14ch;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    
    animation: 1s typing 1s steps(14), effect .5s   alternate;
  }
  .typing-effect-28{
    width: 28ch;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    
    animation: typing 2s steps(28), effect .5s step-end infinite alternate;
  }
  .typing-effect-17{
    width: 17ch;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    animation: typing 2s steps(17), effect .5s step-end infinite alternate;
  }
  .typing-effect-40{
    width: 40ch;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    animation: typing 2s steps(40), effect .5s step-end infinite alternate;
  }
  
  .highlightEmpty{
  input:placeholder-shown {
    border-width: 4px;
  }
}

  
}


